import Service from "../Service";

const resource = "CntBudget/";

export default {
    
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    create(prs, requestID) {
        return Service.post(resource + "create", prs, {
            params: { usrID: requestID },
        });
    },
    listBudgetDetail(parameters, requestID) {
        return Service.post(resource + "listBudgetDetail", parameters, {
            params: { requestID }
        });
    },
    save(wkr, requestID) {
        return Service.post(resource + "create", wkr, {
            params: { usrID: requestID },
        });
    },
};