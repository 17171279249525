<template>
	<div>
        
		<s-crud
			title="PRESUPUESTO"
            :config="config"
             edit
             add       
			 @addEvent="addRecom()"          
             @save="save($event)"                
            remove
             :filter="filter"
              search-input
              height="170"
              ref="crudBudget"
            @rowSelected="rowSelected($event)" 
			searchInput
		>
			<template v-slot:filter>
				<v-container>
					<v-row>
						<v-col>
							<s-date
								label="Fecha Inicio" 
								v-model="filter.DateBegin" 
							></s-date>
						</v-col>
						<v-col>
							<s-date
								label="Fecha Fin" 
								v-model="filter.DateEnd" 
							></s-date>
						</v-col>
                        <!-- <v-col>
							<s-select-definition
								label="Estado"
								:def="1479"
								v-model="filter.AgtStatus"
								clearable
							></s-select-definition>
						</v-col> -->
					</v-row>
				</v-container>
			</template>
            <template slot-scope="props">
                        <v-card>
                            <v-container>
                            <v-col>
                                <v-row>
                                <h2>{{TitleView}}</h2>
                                </v-row>
                            </v-col>
                            <v-row>
                                <v-col>
                                <v-divider></v-divider>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col lg="12" >
                                <v-row justify="center">
									<v-col lg="4" class="pt-0 pb-0" cols="12">
									<s-select-definition label="Tipo Costo" itemtext="GenHelper" :def="1045" return-object v-model="TypeCostObject"/>
									</v-col>
									<v-col lg="4" class="pt-0 pb-0" cols="12"> 
									<s-select-area 	v-model="selectedCenterCost" :text="CceName" centercost label="Centro de Costo" return-object	/>
									</v-col>
									<v-col lg="4" class="pt-0 pb-0" cols="12">
									<s-select-business-line v-model="selectedBusinessLine"  :cceID="selectedCenterCost.GenID" return-object label="Línea de Negocio"/>
									</v-col>
                                   
                                </v-row>
								<v-row  justify="center">
									<v-col lg="4" class="pt-0 pb-0" cols="12">
                                    <s-text
                                        label="Descripcion"
                                        ref="refDescription"
                                        v-model="Description"/>
                                    </v-col>
									
                                    <v-col lg="3" class="pt-0 pb-0" cols="12">
                                    <s-text
                                        label="Cantidad"
                                        ref="refBgdAccount"
                                        number
                                        v-model="BgdAccount">
                                    </s-text>
                                    </v-col>
                                    <v-col lg="4" class="pt-0 pb-0" cols="12">
                                    <s-text
                                        label="Monto"
                                        ref="refBgdAmount"
                                        decimal
                                        v-model="BgdAmount"/>
                                    </v-col>
                                    <v-col lg="1" class="pt-0" cols="12">
                                    <v-btn
									    v-if="!IsEdit"
                                        fab
                                        class="mt-2"
                                        color="success"
                                        rounded
                                        @click="addProduct()"
                                        small
                                        block>
                                        <v-icon height="20" style="font-size: 14px !important"
                                        >fas fa-plus</v-icon>
                                        </v-btn
                                    >
									<v-btn
									    v-if="IsEdit"
										fab
										class="mt-2"			
										color="info"
										rounded
										small
										block
										@click="editProduct()"
										>
										<v-icon height="20" style="font-size: 14px !important"
										>fas fa-edit</v-icon>				
										</v-btn	
										>
                                    </v-col>
								</v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                    <v-data-table
									              @click:row="rowClick"
                                        :items="filteredItems"
                                        :headers="[
                                        { text: '',                 value: 'BgdID' },
                                        { text: 'Description',         value: 'Description' },
										{ text: 'Centro de costo',         value: 'CceName' },
										{ text: 'Linea de negocio',         value: 'BslName' },
										{ text: 'Cantidad',             value: 'BgdAccount' },
                                        { text: 'Monto',    value: 'BgdAmount'},
                                        { text: 'Tipo de costo',            value: 'TypeCostName' },
                                        {
                                            text: 'Acciones',
                                            value: 'Actions',
                                            align: 'center',
                                            width: '90',
                                            sortable: false,
                                        },
                                        ]"
                                        dense
                                        :items-per-page="5"
					
										>
                                        <template v-slot:item.Actions="{ item }">
                                        <v-btn
                                            @click="clickDeleteDetail(item)"
                                            x-small
                                            color="error"
                                            elevation="0"
                                            block>
                                            <v-icon style="font-size: 16px !important"
                                            >fas fa-times</v-icon
                                            >
                                        </v-btn>
										
                                        </template>
                                    </v-data-table>
                                    </v-col>
                                </v-row>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top: 20px;">
                                <v-col>
                                <v-row>
                                    <v-col lg="6" class="pt-0 pb-0" cols="12">
                                    <s-select-area
                                        label="Area"
                                        v-model="AreID"
										:text="AreName"
										ref="refAre"
                                    ></s-select-area>
                                    </v-col>
                                    <v-col lg="3" class="pt-0 pb-0" cols="12">
                                    <s-text
                                        label="Cantidad Total"
                                        ref="refAccount"
                                        number
                                        v-model="BgtAccount"
										readonly
										>
                                    </s-text>
                                    </v-col>
									<v-col lg="3" class="pt-0 pb-0" cols="12">
                                    <s-text
                                        label="Monto Total"
                                        ref="refAmount"
                                        decimal
                                        v-model="BgtAmount"
										readonly
										/>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col lg="6" class="pt-0">
                                    <s-date label="Fecha Inicio" v-model="DateBegin"> </s-date>
                                    </v-col>
                                    <v-col lg="6" class="pt-0">
                                    <s-date label="Fecha Fin" v-model="DateEnd"> </s-date>
                                    </v-col>
                                </v-row>                                
                                </v-col>
                            </v-row>
                            </v-container>
                        </v-card>
   
                            
                    </template>
			<template v-slot:options>
			</template>

			<!-- <template v-slot:Status="{ row }">
				<v-chip 
					x-small 
					black
					:color="row.CtrStatus==1?'success':'warning'"
				>
					{{ row.HmnEstado }}
				</v-chip>
			</template>
			<template v-slot:DisProDep="{ row }">
			
				{{ row.DisName }} - {{ row.ProName }} - {{ row.DepName }}
			
			</template>
			<template v-slot:Action="{ row }">
				<v-btn
					x-small
					color="info"
					@click="clickActionTxt(row)"
				>
				<v-icon>mdi-file-document</v-icon>{{'txt'}}
				</v-btn>

			 </template> -->
		</s-crud>

	</div>
</template>

<script>
	import _sContractService from '@/services/Accounting/BudgetService.js';
    import SSelectBusinessLine from "@/components/Utils/SSelectBusinessLine.vue";
    // import ContractAdd from "@/views/HumanResource/ContractAdd.vue";
	// import ContractEdit from "@/views/HumanResource/ContractEdit.vue";

	export default {
  	components: {  SSelectBusinessLine},
		data() {
			return {
				AreID:null,
				AreName:null,
				BgtID:null,
				BgdID:null,
				DateEnd:null,
				DateBegin:null,
                showEdit: false,
				IsEdit: false,
				selectedCenterCost: 0,   
				selectedBusinessLine: null,   
				BgtAmount:null,
				BgtAccount:null, 
				BgdAmount:null,
				BgdAccount:null, 
				CceName:null,
				TypeCostObject:null,
				TypeCost:0,
				TypeCostName:'',
				itemsAppl :[],
				CntBudget:{},
				Description:null,
				TitleView:"Nuevo Presupuesto",
				filter: {
					DateEnd: null,
					DateEnd:null,
				},
				config: {
					model: {
						BgtID: "ID",
						Action: "Action",
					},
					service: _sContractService,
					headers: [
						{text: "ID"									, value: "BgtID"},
						{text: "Area"							    , value: "AreName"},
						{text: "Cantidad"		                    , value: "BgtAccount"},
						{text: "Monto"								, value: "BgtAmount"},
						{text: "Fecha Inicio"					    , value: "DateBegin"},
						{text: "Fecha Fin"							, value: "DateEnd"},
					]
				},
				
			}
		},
        watch : {
			filter(){
				console.log(this.filter)
			}
		},
		methods: {
			initialize()
			{
			},

			save(item) {
			if(this.itemsAppl.length <= 0){
				this.$fun.alert("Agregar detalle", "warning");
				return;
			}
			if(this.AreID == null || this.AreID == ''){
				 this.$fun.alert("Seleccione Area", "warning"); return; 
			}
			this.CntBudget.BgtID = this.BgtID;
            this.CntBudget.AreID = this.AreID;
			this.CntBudget.BgtAmount = parseFloat(this.BgtAmount).toFixed(2);
			this.CntBudget.BgtAccount = this.BgtAccount;
			this.CntBudget.DateBegin = this.DateBegin;
			this.CntBudget.DateEnd = this.DateEnd;
			this.CntBudget.SecStatus = 1;
			this.CntBudget.BudgetDetail=this.itemsAppl;
            
			
			console.log("CntBudget",this.CntBudget);
			_sContractService.create(this.CntBudget, this.$fun.getUserID()).then((r) => {
			// console.log("person",r);
			// console.log("personSaved", this.person);
			// this.$emit("personSaved", this.person);
			// this.$emit("save", this.person);
			// this.person.GenPerson.PrsDocumentNumber = "";
			 this.clear();
			 this.$fun.alert("Guardado Correctamente", "success");
			// this.close();
			 this.$refs.crudBudget.refresh();
			});
  
			},

			addRecom(){
                this.itemsAppl = [];
				this.BgtID=null;
				this.clear();
				this.clearble();
            },
			clearble(){
				this.Description = null;
				this.BgdAccount = null;
				this.BgdAmount = null;
				//this.TypeCostObject = null;
				// this.TypeCostObject.DedValue=1;
				// this.TypeCostObject.DedDescription="COSTO FIJO",
				this.CceName="";
				this.selectedCenterCost=0;
				this.selectedBusinessLine=null;
				this.TitleView="Nuevo Presupuesto";
				this.IsEdit = false;
			},
			clear(){
				this.BgtAccount=null;
				this.BgtAmount =null;
				this.AreID=null;
				this.AreName=null;
				this.itemsAppl=[];
			},
			rowSelected(row) {
				this.clearble();
				if (row.length == 0) {
                    return;
                }
                console.log("roww", row);
                console.log("roww", row[0].AreName);
				this.AreID = row[0].AreID;
				this.AreName=row[0].AreName;
				this.DateBegin = row[0].DateBegin;
				this.DateEnd = row[0].DateEnd;
                this.BgtID =row[0].BgtID;
                _sContractService.listBudgetDetail(row[0], this.$fun.getUserID()).then((r) => {

                    if (r.status == 200) { 
                        
                        console.log("listBudgetDetail", r.data)
                        this.itemsAppl = r.data;
                    }
                })	
			},
			rowClick(item) {
				console.log("pipi", item);
				this.BgdID = item.BgdID; // este es su ID de la tabala detalle
				this.Description = item.Description;
				this.BgdAccount = item.BgdAccount;
				this.BgdAmount = item.BgdAmount;
				this.CceName =   item.CceName ;
				this.selectedBusinessLine = this.selectedBusinessLine || { GenID: item.BslID, GenDescription: item.BslName };
				this.TypeCostObject=item.TypeCost;
				this.TypeCost=item.TypeCost;
				this.TypeCostName=item.TypeCostName;
				// this.TypeCostObject.DedValue = item.TypeCost;
				//this.TypeCostObject.DedDescription = item.TypeCostName;
				this.TitleView = "Editar Detalle";
				this.IsEdit=true;
				// También puedes agregar más lógica de validación aquí si es necesario
			},
			clickDeleteDetail(item) {

			if(this.itemsAppl.length > 0){
			if(item.BgdID > 0){
			  this.itemsAppl.forEach(element => {
                if (element.BgdID === item.BgdID) {
                    element.SecStatus = 0;
                }
              });
			}else{
				const index = this.itemsAppl.indexOf(item); // Encuentra el índice del elemento
				if (index !== -1) {
					this.itemsAppl.splice(index, 1); // Elimina el elemento en ese índice
				}
			}
			}
			},
			editProduct() {
				// Validación de los campos requeridos
				if (this.selectedCenterCost == null) {
					this.$fun.alert("El campo Centro de Costo es obligatorio", "warning");
					return;
				}
				if (this.selectedBusinessLine == null) {
					this.$fun.alert("El campo Línea de Negocio es obligatorio", "warning");
					return;
				}
				if (!this.TypeCostObject) {
					this.$fun.alert("Seleccione Tipo de costo", "warning");
					return;
				}
				if (!this.Description) {
					this.$fun.alert("Completar Descripción", "warning");
					return;
				}
				if ((this.BgdAmount) <= 0) {
					this.$refs.refBgdAccount.error("Monto incorrecto");
					return;
				}
				if ((this.BgdAccount) <= 0) {
					this.$refs.refBgdAccount.error("Cantidad incorrecta");
					return;
				}

				// Buscar el item en la lista 'itemsAppl' usando el BgdID
				const index = this.itemsAppl.findIndex(item => item.BgdID === this.BgdID);
				console.log("ddd",this.TypeCostObject.DedValue);
				// Si el item existe, actualizarlo
				if (index !== -1) {
					// Reemplazamos el objeto completo para asegurar que Vue lo detecte
					this.$set(this.itemsAppl, index, {
						BgdID: this.BgdID, // Aseguramos que el ID no se pierda
						BgtID:this.BgtID,
						Description: this.Description,
						BgdAccount: parseInt(this.BgdAccount),
						BgdAmount: parseFloat(this.BgdAmount).toFixed(2),
						TypeCost: this.TypeCostObject.DedValue ??this.TypeCost,
						TypeCostName: this.TypeCostObject.DedDescription??this.TypeCostName,
						CceID: this.selectedCenterCost.GenID,
						CceName: this.selectedCenterCost.GenDescription,
						BslID: this.selectedBusinessLine.GenID,
						BslName: this.selectedBusinessLine.GenDescription,
						SecStatus: 1, // Suponiendo que el item sigue activo
						UsrUpdateID: this.$fun.getUserID(), // ID del usuario que actualiza el item
					});

					// Limpiar los campos después de la actualización
					this.clearble();
				} else {
					this.$fun.alert("Item no encontrado", "error");
				}
			}
			,

			addProduct() {

				if(this.selectedCenterCost == null ){
				this.$fun.alert("El campo Centro de Costo es obligatorio","warning");
				valid = false;  
				}
				if(this.selectedBusinessLine==null ){
				this.$fun.alert("El campo Linea de Negocio es obligatorio","warning");
				valid = false;  
				}
                if(!this.TypeCostObject){
                 this.$fun.alert("Seleccione Tipo de costo", "warning");
                 return;
                }
                if(!this.Description){
					this.$fun.alert("Completar Descripción", "warning");
					return;
				}
                if ((this.BgdAmount) <= 0) {
                    this.$refs.refBgdAccount.error("Monto incorrecta");
                    return;
                }
				if ((this.BgdAccount) <= 0) {
                    this.$refs.refBgdAccount.error("Cantidad incorrecta");
                    return;
                }
               
                this.itemsAppl.push({
					BgtID:this.BgtID,
                    Description: this.Description,
                    BgdAccount : parseInt(this.BgdAccount),
                    BgdAmount : parseFloat(this.BgdAmount).toFixed(2),
					TypeCost: this.TypeCostObject.DedValue,
					TypeCostName: this.TypeCostObject.DedDescription,
					CceID: this.selectedCenterCost.GenID,
					CceName: this.selectedCenterCost.GenDescription,
					BslID: this.selectedBusinessLine.GenID,
					BslName:this.selectedBusinessLine.GenDescription,
                    SecStatus: 1,
                    UsrCreateID: this.$fun.getUserID(),
                    UsrUpdateID: null,
                });

				this.selectedCenterCost=0;
				this.selectedBusinessLine=null;
				this.Description="";
				this.BgdAmount=0;
				this.BgdAccount=null;
	
               // this.$refs.objProductApplication.focus();
            },
		},

		created () {
			this.initialize();
		},
		computed: {
		filteredItems() {
			this.BgtAccount = this.itemsAppl.filter(item => item.SecStatus === 1).reduce((total, item) => total + parseInt(item.BgdAccount || 0), 0);
			this.BgtAmount  = this.itemsAppl.filter(item => item.SecStatus === 1).reduce((total, item) => total + parseFloat(item.BgdAmount || 0), 0).toFixed(2);
			return this.itemsAppl.filter(item => item.SecStatus === 1);
		},
	    },
		mounted() {
			
			this.filter.PrsID =  this.$fun.getUserInfo().PrsID; 
					
			console.log( "USER INFO",this.$fun.getUserInfo()); 
		}
	}
</script>

<style>
	.scrollable {
		max-height: 450px; /* Ajusta según sea necesario */
		overflow-y: auto;  /* Habilita el scroll vertical */
	}
</style>